import { sha512 } from "js-sha512";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { doc, getDoc } from "firebase/firestore";
import { useEffect } from "react";
import { View } from "react-native";
import { FIRESTORE_DB } from "../handlers/FirebaseHandler";

export default function LaunchScreen() {
  const navigation = useNavigation();

  async function loginUser() {
    try {
      let email = await AsyncStorage.getItem("email");
      let password = await AsyncStorage.getItem("password");
      let encPw = sha512(password);
      console.log(email, password);
      if (email !== undefined && password !== undefined) {
        let user = await getDoc(
          doc(FIRESTORE_DB, "user", email.toLowerCase().trim())
        );
        user = user.data();
        if (user.password == encPw) {
          return user;
        } else return null;
      }
    } catch {
      return null;
    }
  }

  useEffect(() => {
    (async () => {
      let user = await loginUser();
      console.log(user);
      if (user === null) {
        navigation.navigate("LoginScreen");
        return;
      }
      const resetAction = CommonActions.reset({
        index: 1,
        routes: [
          {
            name: "Landing",
            params: {
              user: {
                name: user.name,
                email: user.email.toLowerCase().trim(),
                isAdmin: user.isAdmin,
              },
            },
          },
        ],
      });
      navigation.dispatch(resetAction);
    })();
  });
  return <View></View>;
}
