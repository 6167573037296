import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { FIRESTORE_DB } from "../handlers/FirebaseHandler";
import Icon from "react-native-vector-icons/Ionicons";
import moment from "moment";
import { Colors } from "../const/Colors";
import "moment/locale/de";
import { CommonActions, useNavigation } from "@react-navigation/native";
import background from "../assets/background.png";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function LandingScreen({ route }) {
  const { user } = route.params;
  const [events, setEvents] = useState([]);
  const navigation = useNavigation();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    moment.locale("de");
    const unsub = onSnapshot(collection(FIRESTORE_DB, "events"), (snapshot) => {
      let data = snapshot.docs.map((s) => {
        return s.data();
      });
      data = data.filter((event) => {
        return (
          event.date.toDate() >= new Date().setDate(new Date().getDate() - 1)
        );
      });
      data = data.sort((d1, d2) =>
        d1.date.toDate() < d2.date.toDate() ? -1 : 0
      );
      (async () => {
        let ra = [];
        for (const d of data) {
          let response = await hasOwnEntry(d);
          if (response !== null) {
            ra.push(response);
          }
        }
        setUserData(ra);
      })();

      setEvents(data);
    });
    return () => {
      unsub();
    };
  }, []);

  async function hasOwnEntry(event) {
    const response = await getDocs(
      collection(FIRESTORE_DB, "events", event.id, "answers")
    );
    let data = response.docs.map((r) => {
      return { data: r.data().data, id: r.id };
    });
    let ra = [];
    for (const d of data) {
      //console.log(d);
      if (d.id === user.email.trim()) {
        const coming = d.data[0].answer === "Ja";
        return { id: event.id, coming: coming, all: d.data };
      }
    }
    return null;
  }

  return (
    <View style={{ width: "100%", height: "100%", flex: 1 }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.5,
          left: 0,
          resizeMode: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <SafeAreaView style={styles.container}>
        <View
          style={{
            alignSelf: "stretch",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: 20,
            marginTop: 20,
          }}
        >
          <View>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 22,
              }}
            >
              Rotary Witten
            </Text>
            <Text
              style={{
                fontSize: 17,
              }}
            >
              {user.name}
            </Text>
          </View>
          {user.isAdmin ? (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("AdminOverviewScreen", { data: events })
              }
            >
              <Icon
                name="cog"
                size={32}
                style={{ opacity: 0.15, color: "#000" }}
              />
            </TouchableOpacity>
          ) : null}
        </View>
        <Seperator style={{ opacity: 0.5, marginTop: 12 }} />
        <FlatList
          data={events}
          style={{ flex: 1, width: "100%" }}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("EventOverview", {
                    event: item,
                    user: user,
                  })
                }
              >
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      paddingRight: 16,
                      alignItems: "center",
                    }}
                  >
                    <View style={{ flex: 1, padding: 16 }}>
                      <Text style={{ fontSize: 17, fontWeight: "bold" }}>
                        {getValidDate(item.date)}
                      </Text>
                      <Text style={{ fontSize: 15, marginTop: 2 }}>
                        {item.description}
                      </Text>
                    </View>
                    <UserResponse userData={userData} event={item} />
                  </View>
                  <UserFoodResponse userData={userData} event={item} />
                  <Seperator style={{ opacity: 0.1, marginTop: 12 }} />
                </View>
              </TouchableOpacity>
            );
          }}
        />
        <Seperator style={{ opacity: 0.5, marginTop: 12 }} />
        <View
          style={{
            margin: 12,
            justifyContent: "space-around",
            alignSelf: "stretch",
            flexDirection: "row",
          }}
        >
          <Text style={{ fontSize: 12 }}>© 2022 Hippe IT-Solutions</Text>
          <Text style={{ fontSize: 12 }}>Version 3.1.0</Text>
        </View>

        <View
          style={{
            margin: 12,
            paddingBottom: 12,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ContinueButton
            action={async () => {
              await AsyncStorage.removeItem("email");
              await AsyncStorage.removeItem("password");
              const resetAction = CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: "LoginScreen",
                    params: {},
                  },
                ],
              });
              navigation.dispatch(resetAction);
            }}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

const UserResponse = ({ userData, event }) => {
  //console.log(userData);
  let res = userData.filter((f) => f.id === event.id);
  if (res === undefined || res.length === 0) {
    return null;
  }
  console.log("should return");
  if (res[0].coming) {
    return <Icon name="checkmark-outline" size={36} color={"green"} />;
  } else {
    return <Icon name="close-outline" size={36} color={"#8B0000"} />;
  }
};

const UserFoodResponse = ({ userData, event }) => {
  //console.log(userData);
  let res = userData.filter((f) => f.id === event.id);

  if (res === undefined || res.length === 0) {
    return null;
  }
  if (!res[0].coming) {
    return null;
  }

  let ownFood = res[0].all[1].answer;
  let partnerFood = res[0].all[3].answer;

  if (partnerFood === "-") {
    return (
      <View
        style={{
          backgroundColor: "#ccc",
          paddingHorizontal: 9,
          paddingVertical: 6,
          marginTop: -4,
          alignSelf: "flex-start",
          borderRadius: 12,
          marginLeft: 20,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: "500", fontStyle: "italic" }}>
          {ownFood}
        </Text>
      </View>
    );
  } else {
    return (
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            backgroundColor: "#ccc",
            paddingHorizontal: 9,
            paddingVertical: 6,
            marginTop: -4,
            alignSelf: "flex-start",
            borderRadius: 12,
            marginLeft: 20,
          }}
        >
          <Text
            style={{ fontSize: 14, fontWeight: "500", fontStyle: "italic" }}
          >
            {ownFood}
          </Text>
        </View>
        <View
          style={{
            backgroundColor: "#ccc",
            paddingHorizontal: 9,
            paddingVertical: 6,
            marginTop: -4,
            alignSelf: "flex-start",
            borderRadius: 12,
            marginLeft: 12,
          }}
        >
          <Text
            style={{ fontSize: 14, fontWeight: "500", fontStyle: "italic" }}
          >
            {partnerFood}
          </Text>
        </View>
      </View>
    );
  }
};

export function getValidDate(date) {
  let newDate = date.toDate();
  newDate = new Date(newDate);
  return moment(newDate).format("DD. MMMM YYYY");
}

export const Seperator = ({ style }) => {
  return (
    <View
      style={[
        style,
        {
          width: "100%",
          height: 1,
          backgroundColor: "black",
        },
      ]}
    ></View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
});

const ContinueButton = ({ action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          Abmelden
        </Text>
      </View>
    </TouchableOpacity>
  );
};
