import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LandingScreen from "./screens/LandingScreen";
import EventOverviewScreen from "./screens/EventOverviewScreen";
import QuestionScreen from "./screens/QuestionScreen";
import LoginScreen from "./screens/login/LoginScreen";
import RegistryScreen from "./screens/login/RegistryScreen";
import AdminOverviewScreen from "./screens/admin/AdminOverviewScreen";
import SummaryScreen from "./screens/SummaryScreen";
import SuccessSendScreen from "./screens/SuccessSendScreen";
import LaunchScreen from "./screens/LaunchScreen";
import AddNewEventScreen from "./screens/admin/AdminEventOverviewScreen";
import AdminEventOverviewScreen from "./screens/admin/AdminEventOverviewScreen";

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="LaunchScreen"
          component={LaunchScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="RegistryScreen"
          component={RegistryScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="Landing"
          component={LandingScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="EventOverview"
          component={EventOverviewScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="QuestionScreen"
          component={QuestionScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="AdminOverviewScreen"
          component={AdminOverviewScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="SummaryScreen"
          component={SummaryScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="SuccessSendScreen"
          component={SuccessSendScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
        <Stack.Screen
          name="AdminEventOverviewScreen"
          component={AdminEventOverviewScreen}
          options={{ headerShown: false, title: "Rotary Witten" }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
