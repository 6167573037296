import { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  SafeAreaView,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import background from "../assets/background.png";
import { EventHeader } from "./EventOverviewScreen";
import { Seperator } from "./LandingScreen";
import { ALL_QUESTIONS } from "./QuestionScreen";
import Icon from "react-native-vector-icons/Ionicons";
import { DrawerActions } from "@react-navigation/native";
import { setDoc, doc } from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { FIRESTORE_DB } from "../handlers/FirebaseHandler";
import { Colors } from "../const/Colors";

export default function SummaryScreen({ navigation, route }) {
  const { event, answers, notes, user } = route.params;

  const [data, setData] = useState();

  useEffect(() => {
    let data = ALL_QUESTIONS.map((item, index) => {
      let question = item.title;
      let answer = getAnswer(answers, index);
      return { question: question, answer: answer };
    });
    data.push({ question: "Haben Sie noch Anmerkungen?", answer: notes });
    setData(data);
  }, []);

  return (
    <View style={{ width: "100%", height: "100%", flex: 1 }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.5,
          left: 0,
          resizeMode: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <SafeAreaView
        style={{
          height: "100%",
          width: "100%",
          ...Platform.select({ android: { marginTop: 20 } }),
        }}
      >
        <EventHeader event={event} />
        <Seperator style={{ opacity: 0.5 }} />
        <FlatList
          data={data}
          renderItem={({ item }) => {
            return (
              <View style={{ alignSelf: "stretch", padding: 20 }}>
                <Text style={{ fontWeight: "bold", fontSize: 15 }}>
                  {item.question}
                </Text>
                <Text style={{ fontSize: 17, marginTop: 5 }}>
                  {item.answer}
                </Text>
              </View>
            );
          }}
        />
        <View
          style={{
            alignSelf: "stretch",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: 20,
            ...Platform.select({ android: { paddingBottom: 20 } }),
            alignItems: "center",
          }}
        >
          <BackButton
            action={() => {
              navigation.goBack();
            }}
          />
          <ContinueButton
            action={async () => {
              await setDoc(
                doc(
                  FIRESTORE_DB,
                  "events",
                  event.id,
                  "answers",
                  user.email.trim()
                ),
                { data: data }
              );
              navigation.navigate("SuccessSendScreen", {
                event: event,
                user: user,
              });
            }}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

function getAnswer(answers, index) {
  let res = answers.filter((f) => {
    return f.currentQuestionIndex === index;
  });
  if (res.length > 0) {
    return ALL_QUESTIONS[index].answers[res[0].selectedIndex].title;
  } else return "-";
}

const BackButton = ({ navigation, action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 12,
          paddingVertical: 8,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Icon name="arrow-back" size={20} color={Colors.blue} />
        <Text style={{ marginLeft: 8, fontSize: 15, color: Colors.blue }}>
          zurück
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const ContinueButton = ({ action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            marginRight: 8,
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Abschicken
        </Text>
        <Icon name="arrow-forward" size={24} color={"white"} />
      </View>
    </TouchableOpacity>
  );
};
