import { useEffect, useRef, useState } from "react";
import {
  FlatList,
  Image,
  SafeAreaView,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import background from "../assets/background.png";
import { EventHeader } from "./EventOverviewScreen";
import { Seperator } from "./LandingScreen";
import { default as Lottie, default as LottieView } from "lottie-react-native";
import { CommonActions } from "@react-navigation/native";
import { Colors } from "../const/Colors";
import Icon from "react-native-vector-icons/Ionicons";

export default function SuccessSendScreen({ route, navigation }) {
  const { event, user } = route.params;

  const animationRef = useRef();

  useEffect(() => {
    animationRef.current?.play();

    // Or set a specific startFrame and endFrame with:
    animationRef.current?.play(30, 120);
  }, []);

  return (
    <View style={{ width: "100%", height: "100%", flex: 1 }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.5,
          left: 0,
          resizeMode: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <SafeAreaView
        style={{
          height: "100%",
          width: "100%",
          ...Platform.select({ android: { marginTop: 20 } }),
        }}
      >
        <EventHeader event={event} />
        <Seperator style={{ opacity: 0.5 }} />
        <View
          style={{
            alignSelf: "stretch",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            margin: 50,
          }}
        >
          {Platform.OS === "web" ? (
            <Icon
              name="checkmark-circle-outline"
              size={200}
              color={Colors.blue}
            />
          ) : (
            <LottieView
              ref={animationRef}
              loop={false}
              source={require("../assets/animations/check.json")}
            />
          )}
        </View>
        <View
          style={{
            margin: 20,
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            ...Platform.select({ android: { paddingBottom: 20 } }),
          }}
        >
          <ContinueButton
            action={() => {
              const resetAction = CommonActions.reset({
                index: 1,
                routes: [{ name: "Landing", params: { user: user } }],
              });
              navigation.dispatch(resetAction);
            }}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

const ContinueButton = ({ action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Zurück zur Übersicht
        </Text>
      </View>
    </TouchableOpacity>
  );
};
