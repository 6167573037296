import {
  SafeAreaView,
  View,
  Image,
  TouchableOpacity,
  Animated,
  FlatList,
  Text,
  ScrollView,
  TextInput,
  Platform,
} from "react-native";
import { EventHeader } from "./EventOverviewScreen";
import background from "../assets/background.png";
import { Seperator } from "./LandingScreen";
import React, { useEffect, useState } from "react";
import Icon from "react-native-vector-icons/Ionicons";
import { Colors } from "../const/Colors";

const questionOne = {
  title: "Nehmen Sie teil?",
  answers: [
    {
      title: "Ja",
      nextIndex: 1,
    },
    {
      title: "Nein",
      nextIndex: -1,
    },
  ],
};
const questionTwo = {
  title: "Was essen Sie?",
  answers: [
    {
      title: "Vegetarisch",
      nextIndex: 2,
    },
    {
      title: "'normal'",
      nextIndex: 2,
    },
    {
      title: "Salat",
      nextIndex: 2,
    },
    {
      title: "Kein Essen",
      nextIndex: 2,
    },
  ],
};
const questionThree = {
  title: "Bringen Sie Ihren Partner mit?",
  answers: [
    {
      title: "Ja",
      nextIndex: 3,
    },
    {
      title: "Nein",
      nextIndex: -1,
    },
  ],
};
const questionFour = {
  title: "Was isst Ihr Partner?",
  answers: [
    {
      title: "Vegetarisch",
      nextIndex: -1,
    },
    {
      title: "'normal'",
      nextIndex: -1,
    },
    {
      title: "Salat",
      nextIndex: -1,
    },
    {
      title: "Kein Essen",
      nextIndex: -1,
    },
  ],
};

export let ALL_QUESTIONS = [
  questionOne,
  questionTwo,
  questionThree,
  questionFour,
];

export default function QuestionScreen({ route, navigation }) {
  const { event, user } = route.params;

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [note, setNote] = useState("");
  const [previousSelections, setPreviousSelections] = useState([]);

  useEffect(() => {
    if (!event.hasFood) {
      ALL_QUESTIONS[1] = {
        title: "Was essen Sie?",
        answers: [
          {
            title: "Kein Essen",
            nextIndex: 2,
          },
        ],
      };
      ALL_QUESTIONS[3] = {
        title: "Was essen Sie?",
        answers: [
          {
            title: "Kein Essen",
            nextIndex: -1,
          },
        ],
      };
    } else {
      ALL_QUESTIONS[1] = questionTwo;
      ALL_QUESTIONS[3] = questionFour;
    }
  });

  return (
    <View style={{ width: "100%", height: "100%", flex: 1 }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.5,
          left: 0,
          resizeMode: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <SafeAreaView
        style={{
          height: "100%",
          width: "100%",
          ...Platform.select({ android: { marginTop: 20 } }),
        }}
      >
        <EventHeader event={event} />
        <Seperator style={{ opacity: 0.5 }} />
        <ScrollView
          style={{
            flex: 1,
            alignSelf: "stretch",
            padding: 20,
          }}
          contentContainerStyle={{
            justifyContent: "center",
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 22, fontWeight: "bold", marginBottom: 35 }}>
            {currentQuestionIndex === -1
              ? "Haben Sie noch Anmerkungen?"
              : ALL_QUESTIONS[currentQuestionIndex].title}
          </Text>
          {currentQuestionIndex === -1 ? (
            <TextInput
              multiline
              value={note}
              onChangeText={(t) => {
                setNote(t);
              }}
              style={{
                flex: 1,
                textAlignVertical: "top",
                alignSelf: "stretch",
                padding: 12,
                paddingTop: 12,
                borderRadius: 6,
                backgroundColor: "white",
              }}
            />
          ) : (
            ALL_QUESTIONS[currentQuestionIndex].answers.map(
              (question, index) => {
                return (
                  <QuestionBox
                    title={question.title}
                    key={Math.random()}
                    selectedIndex={selectedIndex}
                    index={index}
                    setSelectedIndex={setSelectedIndex}
                  />
                );
              }
            )
          )}
        </ScrollView>
        <View
          style={{
            alignSelf: "stretch",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: 20,
            ...Platform.select({ android: { paddingBottom: 20 } }),
            alignItems: "center",
          }}
        >
          <BackButton
            action={() => {
              if (currentQuestionIndex === 0) {
                navigation.goBack();
                return;
              }
              let last = previousSelections[previousSelections.length - 1];
              setCurrentQuestionIndex(last.currentQuestionIndex);
              setSelectedIndex(last.selectedIndex);
              let temp = previousSelections;
              temp.pop();
              setPreviousSelections(temp);
            }}
          />
          <ContinueButton
            action={() => {
              //previousSelections = [...new Set(previousSelections)];
              if (currentQuestionIndex !== -1) {
                let temp = previousSelections;
                temp.push({
                  currentQuestionIndex: currentQuestionIndex,
                  selectedIndex: selectedIndex,
                });
                setPreviousSelections(temp);
                setCurrentQuestionIndex(
                  ALL_QUESTIONS[currentQuestionIndex].answers[selectedIndex]
                    .nextIndex
                );
                setSelectedIndex(-1);
              } else {
                navigation.navigate("SummaryScreen", {
                  event: event,
                  answers: previousSelections,
                  notes: note,
                  user: user,
                });
              }
            }}
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

const QuestionBox = ({
  title,
  action,
  selectedIndex,
  index,
  setSelectedIndex,
}) => {
  return (
    <TouchableOpacity
      style={{ alignSelf: "stretch" }}
      onPress={() => {
        if (selectedIndex === index) {
          setSelectedIndex(-1);
          return;
        }
        setSelectedIndex(index);
      }}
    >
      <View
        style={{
          backgroundColor: "white",
          padding: 20,
          borderColor: index === selectedIndex ? "black" : "transparent",
          borderWidth: 1,
          borderRadius: 6,
          alignItems: "center",
          alignSelf: "stretch",
          marginVertical: 12,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: 17, fontWeight: "600" }}>{title}</Text>
        <Icon
          name={
            index === selectedIndex
              ? "checkmark-circle-outline"
              : "ellipse-outline"
          }
          size={27}
        />
      </View>
    </TouchableOpacity>
  );
};

const BackButton = ({ navigation, action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 12,
          paddingVertical: 8,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Icon name="arrow-back" size={20} color={Colors.blue} />
        <Text style={{ marginLeft: 8, fontSize: 15, color: Colors.blue }}>
          zurück
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const ContinueButton = ({ action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            marginRight: 8,
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Weiter
        </Text>
        <Icon name="arrow-forward" size={24} color={"white"} />
      </View>
    </TouchableOpacity>
  );
};
