import {
  View,
  SafeAreaView,
  Text,
  TouchableOpacity,
  ScrollView,
  TextInput,
  Switch,
  Alert,
  ActivityIndicator,
  Share,
  Platform,
} from "react-native";
import moment from "moment";
import "moment/locale/de";
import Icon from "react-native-vector-icons/Ionicons";
import { getValidDate, Seperator } from "../LandingScreen";
import { useEffect, useState } from "react";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { FIRESTORE_DB } from "../../handlers/FirebaseHandler";

import { getUserData } from "../EventOverviewScreen";
import { Colors } from "../../const/Colors";

export default function AdminEventOverviewScreen({ route, navigation }) {
  const { shouldNotify, data } = route.params;

  const [answers, setAnswers] = useState([]);
  const [userData, setUserData] = useState([]);

  const [description, setDescription] = useState(data.description);
  const [hasFood, setHasFood] = useState(data.hasFood);

  const [closed, setClosed] = useState(data.closed);

  const [hasSave, setHasSave] = useState(false);

  const [generating, setGenerating] = useState(false);

  async function saveEvent() {
    try {
      await setDoc(
        doc(FIRESTORE_DB, "events", data.id),
        { description: description, hasFood: hasFood },
        { merge: true }
      );
      return true;
    } catch {
      return false;
    }
  }

  async function deleteEvent() {
    try {
      await deleteDoc(doc(FIRESTORE_DB, "events", data.id));
      return true;
    } catch {
      return false;
    }
  }

  async function toggleEvent(state) {
    try {
      await setDoc(
        doc(FIRESTORE_DB, "events", data.id),
        { closed: state },
        { merge: true }
      );
      return true;
    } catch {
      return false;
    }
  }

  useEffect(() => {
    if (data.description !== description || data.hasFood !== hasFood) {
      setHasSave(true);
    }
  }, [hasFood, description]);

  useEffect(() => {
    (async () => {
      const response = await getDocs(
        collection(FIRESTORE_DB, "events", data.id, "answers")
      );
      let d = response.docs.map((r) => {
        return { data: r.data().data, id: r.id };
      });
      let ra = [];
      for (const item of d) {
        //console.log(d);
        let user = await getUserData(item.id);
        if (user !== undefined) {
          const coming = item.data[0].answer === "Ja";
          const partner = item.data[2].answer === "Ja";
          ra.push({
            name: user.name,
            coming: coming,
            partner: partner,
            all: item.data,
          });
        }
      }
      setUserData(ra);
      setAnswers(d);
    })();
  }, []);

  return (
    <SafeAreaView style={{ height: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          alignSelf: "stretch",
          padding: 8,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          style={{ padding: 3, marginRight: 12 }}
          onPress={() => {
            if (hasSave) {
              Alert.alert(
                "Ungespeicherte Änderungen",
                "Beim Verlassen werden die ungespeicherten Änderungen zurückgesetzt.",
                [
                  {
                    text: "Abbrechen",
                    style: "cancel",
                  },
                  { text: "Verlassen", onPress: () => navigation.goBack() },
                ]
              );
            } else {
              navigation.goBack();
            }
          }}
        >
          <Icon name="chevron-back" size={28} />
        </TouchableOpacity>
        <Text style={{ fontWeight: "bold", fontSize: 20 }}>
          {getValidDate(data.date)}
        </Text>
        <View style={{ flex: 1, justifyContent: "flex-end" }}>
          <TouchableOpacity
            style={{ padding: 3, marginRight: 12, alignSelf: "flex-end" }}
            onPress={async () => {
              if (Platform.OS === "web") {
                if (
                  window.confirm("Termin wirklich unwiederruflich löschen?")
                ) {
                  let success = await deleteEvent();
                  if (success) navigation.goBack();
                }
                return;
              }
              Alert.alert(
                "Termin wirklich löschen?",
                "Alle Einträge sind unwiederruflich gelöscht.",
                [
                  {
                    text: "Abbrechen",
                    style: "cancel",
                  },
                  {
                    text: "Löschen",
                    onPress: async () => {
                      let success = await deleteEvent();
                      if (success) navigation.goBack();
                    },
                  },
                ]
              );
            }}
          >
            <Icon name="trash" size={28} />
          </TouchableOpacity>
        </View>
        {generating ? (
          <ActivityIndicator style={{ width: 34, marginRight: 12 }} />
        ) : (
          <TouchableOpacity
            style={{ padding: 3, marginRight: 12, alignSelf: "flex-end" }}
            onPress={async () => {
              setGenerating(true);
              let res = await generateCSV();
              try {
                const result = await Share.share({
                  message: res,
                });
                if (result.action === Share.sharedAction) {
                  if (result.activityType) {
                    // shared with activity type of result.activityType
                  } else {
                    // shared
                  }
                } else if (result.action === Share.dismissedAction) {
                  // dismissed
                }
              } catch (error) {
                alert(error.message);
              }
              setGenerating(false);
            }}
          >
            <Icon name="share-outline" size={28} />
          </TouchableOpacity>
        )}
      </View>
      <Seperator />
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <Text
          style={{
            alignSelf: "flex-start",
            marginLeft: 20,
            marginTop: 20,
            fontWeight: "bold",
            fontSize: 15,
          }}
        >
          Beschreibung:
        </Text>
        <TextInput
          multiline
          value={description}
          onChangeText={(t) => setDescription(t)}
          style={{
            marginTop: 12,
            backgroundColor: "#ddd",
            width: "90%",
            padding: 12,
            paddingTop: 12,
            height: 120,
            borderRadius: 6,
          }}
        />
        <View
          style={{
            padding: 20,
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <Switch
            value={hasFood}
            thumbColor={"white"}
            activeThumbColor={"white"}
            trackColor={{ true: Colors.blue }}
            onValueChange={() => setHasFood((v) => !v)}
          />
          <Text style={{ marginLeft: 12, fontSize: 15, fontWeight: "bold" }}>
            Essen
          </Text>
        </View>
        <Overview answers={answers} />
        <Participants userData={userData} />
      </ScrollView>

      <View
        style={{
          margin: 20,
          alignSelf: "stretch",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {hasSave || shouldNotify ? (
          <ContinueButton
            shouldNotify={shouldNotify}
            action={async () => {
              let success = await saveEvent();
              if (success) {
                if (shouldNotify) {
                  await sendNotification(data);
                }
                navigation.goBack();
              }
            }}
          />
        ) : null}
        <CloseEventButton
          action={async () => {
            let success = await toggleEvent(!closed);
            if (success) {
              setClosed(!closed);
            }
          }}
          closed={closed}
        />
      </View>
    </SafeAreaView>
  );
}

async function sendNotification(data) {
  try {
    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization:
          "key=AAAATlSyuFE:APA91bF1sTR9soq_wnN6gEVHqqsc1REQ-vCedNax0pTYbaFt5tbF7he4NvvrRHIv-gvH8tC0TWib7HhRu8tyTIup0THAk7zF_9wX-94DmrPjkVcEtrNPOR8pEUEn5D0muJeggFDV7Qnc",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: "/topics/all",
        notification: {
          title: "Neuer Termin: " + getValidDate(data.date),
          body: "Öffne die App, um Dich einzutragen.",
          mutable_content: true,
          sound: "Tri-tone",
        },
      }),
    });
    console.log("response", response);
  } catch {
    console.log("something went wrong");
  }
}

const ContinueButton = ({ action, shouldNotify }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          Speichern{shouldNotify ? " & Notification" : ""}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
const CloseEventButton = ({ action, closed }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Text
          style={{
            color: Colors.blue,
            fontSize: 12,
            fontWeight: "bold",
          }}
        >
          {closed ? "Termin öffnen" : "Termin schließen"}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const Overview = ({ data, answers }) => {
  return (
    <View
      style={{
        alignSelf: "stretch",
        borderRadius: 6,
        marginHorizontal: 20,
        marginBottom: 20,
        backgroundColor: "#ddd",
        padding: 12,
      }}
    >
      <Text
        style={{
          alignSelf: "flex-start",
          fontWeight: "bold",
          fontSize: 15,
          marginBottom: 20,
          textDecorationLine: "underline",
        }}
      >
        Übersicht
      </Text>
      <ListItem title="Teilnehmer" text={getCountOfParticipants(answers)} />
      <ListItem title="Partner" text={getCountOfPartners(answers)} />
      <Seperator style={{ marginVertical: 6 }} />
      {getFoodCount(answers).map((m) => {
        return <ListItem key={Math.random()} title={m.name} text={m.count} />;
      })}
    </View>
  );
};

const Participants = ({ userData }) => {
  return (
    <View
      style={{
        alignSelf: "stretch",
        borderRadius: 6,
        marginHorizontal: 20,
        backgroundColor: "#ddd",
        padding: 12,
      }}
    >
      <Text
        style={{
          alignSelf: "flex-start",
          fontWeight: "bold",
          fontSize: 15,
          textDecorationLine: "underline",
          marginBottom: 20,
        }}
      >
        Teilnehmer
      </Text>
      {userData.map((user) => {
        return (
          <View style={{ padding: 12, alignSelf: "stretch", flex: 1 }}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 15, fontWeight: "500", flex: 1 }}>
                  {user.name} {user.partner ? "+ P " : ""}
                </Text>
                <Text
                  style={{ marginTop: 4, fontStyle: "italic", fontSize: 12 }}
                >
                  {user.all[4].answer}
                </Text>
              </View>
              {user.coming ? (
                <Icon name="checkmark-outline" size={24} color={"green"} />
              ) : (
                <Icon name="close-outline" size={24} color={"#8B0000"} />
              )}
              {/* <Icon
                name="chevron-forward"
                size={24}
                style={{ marginLeft: 12 }}
              /> */}
            </View>
            <Seperator
              style={{ marginTop: 12, marginBottom: -12, opacity: 0.5 }}
            />
          </View>
        );
      })}
    </View>
  );
};

function getCountOfParticipants(data) {
  let count = 0;
  data.forEach((m) => {
    if (m.data[0].answer === "Ja") {
      count += 1;
    }
  });
  return count;
}
function getCountOfPartners(data) {
  let count = 0;
  data.forEach((m) => {
    if (m.data[2].answer === "Ja") {
      count += 1;
    }
  });
  return count;
}
function getFoodCount(data) {
  let _0 = 0;
  let _1 = 0;
  let _2 = 0;
  let _3 = 0;

  data.forEach((m) => {
    if (m.data[1].answer === "Vegetarisch") {
      _0 += 1;
    } else if (m.data[1].answer === "'normal'") {
      _1 += 1;
    } else if (m.data[1].answer === "Salat") {
      _2 += 1;
    } else if (m.data[1].answer === "Kein Essen") {
      _3 += 1;
    }
    if (m.data[3].answer === "Vegetarisch") {
      _0 += 1;
    } else if (m.data[3].answer === "'normal'") {
      _1 += 1;
    } else if (m.data[3].answer === "Salat") {
      _2 += 1;
    } else if (m.data[3].answer === "Kein Essen") {
      _3 += 1;
    }
  });
  return [
    { name: "Vegearisch", count: _0 },
    { name: "'normal'", count: _1 },
    { name: "Salat", count: _2 },
    { name: "Kein Essen", count: _3 },
  ];
}

const ListItem = ({ title, text }) => {
  return (
    <View
      style={{
        alignSelf: "stretch",
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 6,
      }}
    >
      <Text style={{ fontWeight: "bold", fontSize: 15 }}>{title}</Text>
      <Text style={{ fontSize: 15 }}>{text}</Text>
    </View>
  );
};

async function generateCSV(answers, data) {
  const values = [
    ["build", "2017-11-05T05:40:35.515Z"],
    ["deploy", "2017-11-05T05:42:04.810Z"],
  ];

  // construct csvString
  const headerString = "event,timestamp\n";
  const rowString = values.map((d) => `${d[0]},${d[1]}\n`).join("");
  const csvString = `${headerString}${rowString}`;

  // write the current list of answers to a local csv file

  return csvString;
}
