import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { sha512 } from "js-sha512";
import { CommonActions } from "@react-navigation/native";
import { useState } from "react";
import {
  Keyboard,
  Platform,
  SafeAreaView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Colors } from "../../const/Colors";
import { doc, getDoc } from "firebase/firestore";
import { FIRESTORE_DB } from "../../handlers/FirebaseHandler";
import Icon from "react-native-vector-icons/Ionicons";

export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [saveData, setSaveData] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);

  async function loginUser() {
    let encPw = sha512(password);
    try {
      let user = await getDoc(
        doc(FIRESTORE_DB, "user", email.toLowerCase().trim())
      );
      user = user.data();
      if (user.password == encPw) return user;
      else return null;
    } catch {
      return null;
    }
  }

  return (
    <TouchableWithoutFeedback
      style={{ width: "100%", height: "100%" }}
      onPress={() => {
        if (Platform.OS === "web") return;
        Keyboard.dismiss();
      }}
    >
      <SafeAreaView
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#ddd",
          ...Platform.select({ android: { paddingTop: 20 } }),
        }}
      >
        <KeyboardAwareScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <Text
            style={{
              padding: 20,
              marginTop: 12,
              fontSize: 32,
              fontWeight: "bold",
            }}
          >
            Anmelden
          </Text>
          <View
            style={{
              alignSelf: "stretch",
              flex: 1,
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Text style={{ fontSize: 15, marginBottom: 20 }}>
              Bitte melden Sie sich mit Ihrem Account an:
            </Text>
            <TextInput
              placeholder={"E-Mail"}
              value={email}
              onChangeText={(t) => setEmail(t)}
              inputType="email"
              style={{
                marginTop: 20,
                borderRadius: 6,
                backgroundColor: "white",
                padding: 12,
                fontSize: 17,
              }}
            />
            <TextInput
              inputType="password"
              secureTextEntry
              value={password}
              onChangeText={(t) => setPassword(t)}
              placeholder={"Passwort"}
              style={{
                marginTop: 20,
                borderRadius: 6,
                backgroundColor: "white",
                padding: 12,
                fontSize: 17,
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 20,
              }}
            >
              <Switch
                value={saveData}
                thumbColor={"white"}
                activeThumbColor={"white"}
                trackColor={{ true: Colors.blue }}
                onValueChange={() => setSaveData((v) => !v)}
                style={{ marginRight: 20 }}
              />
              <Text style={{ fontSize: 15 }}>Anmeldedaten speichern</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 40,
              }}
            >
              <ContinueButton
                disabled={email === "" || password === "" || isWaiting}
                action={async () => {
                  setIsWaiting(true);
                  let user = await loginUser();
                  console.log(user);
                  if (user !== null) {
                    await AsyncStorage.setItem("did_login", "0");
                    if (saveData) {
                      console.log("save data");
                      await AsyncStorage.setItem(
                        "email",
                        email.toLowerCase().trim()
                      );
                      await AsyncStorage.setItem("password", password);
                    }
                    const resetAction = CommonActions.reset({
                      index: 1,
                      routes: [
                        {
                          name: "Landing",
                          params: {
                            user: {
                              name: user.name,
                              email: email.toLowerCase().trim(),
                              isAdmin: user.isAdmin,
                            },
                          },
                        },
                      ],
                    });
                    navigation.dispatch(resetAction);
                  }
                  setIsWaiting(false);
                }}
              />
            </View>
            <View
              style={{
                marginTop: 20,
                alignSelf: "center",
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            ></View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

const LoginButton = ({ navigation }) => {
  return (
    <TouchableOpacity onPress={() => navigation.navigate("RegistryScreen")}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 15, color: Colors.blue, fontWeight: "bold" }}>
          Ich habe noch keinen Account
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const ContinueButton = ({ action, disabled }) => {
  return (
    <TouchableOpacity onPress={action} disabled={disabled}>
      <View
        style={{
          flexDirection: "row",
          opacity: disabled ? 0.5 : 1,
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            marginRight: 8,
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Anmelden
        </Text>
        <Icon name="arrow-forward" size={20} color={"white"} />
      </View>
    </TouchableOpacity>
  );
};
