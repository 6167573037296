import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { setDoc, collection, doc, afffoc, getDoc } from "firebase/firestore";
import { sha512 } from "js-sha512";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import uuid from "react-native-uuid";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  Switch,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { Colors } from "../../const/Colors";
import { FIRESTORE_DB } from "../../handlers/FirebaseHandler";
import { Seperator } from "../LandingScreen";

export default function RegistryScreen({ navigation }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [saveData, setSaveData] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  async function registerUser() {
    let encPw = sha512(password);
    const body = {
      name: name,
      email: email.toLowerCase().trim(),
      password: encPw,
      isAdmin: saveData,
    };
    if (await hasEmail()) {
      return false;
    }
    try {
      await setDoc(doc(FIRESTORE_DB, "user", email.toLowerCase().trim()), body);
      return true;
    } catch {
      return false;
    }
  }

  async function hasEmail() {
    try {
      const doc = await getDoc(
        doc(FIRESTORE_DB, "user", email.toLowerCase().trim())
      );
      console.log(doc);
      const data = doc.data();
      return true;
    } catch {
      return false;
    }
  }

  return (
    <TouchableWithoutFeedback
      style={{
        width: "100%",
        height: "100%",
      }}
      onPress={() => {
        if (Platform.OS === "web") return;
        Keyboard.dismiss();
      }}
    >
      <SafeAreaView
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          ...Platform.select({ android: { paddingTop: 20 } }),
        }}
      >
        <KeyboardAwareScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "stretch",
              padding: 8,
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{ padding: 3, marginRight: 12 }}
              onPress={() => {
                navigation.goBack();
              }}
            >
              <Icon name="chevron-back" size={28} />
            </TouchableOpacity>
            <Text style={{ fontSize: 22, fontWeight: "bold" }}>
              Registrieren
            </Text>
          </View>
          <Seperator />
          <View
            style={{
              alignSelf: "stretch",
              flex: 1,
              justifyContent: "center",
              padding: 20,
            }}
          >
            <Text style={{ fontSize: 17, marginBottom: 20 }}>
              Hier die Account-Daten eingeben:
            </Text>
            <View>
              <TextInput
                placeholder={"Vor- und Nachname"}
                inputType="name"
                value={name}
                onChangeText={(t) => setName(t)}
                style={{
                  borderRadius: 6,
                  backgroundColor: "#ddd",
                  padding: 12,
                  fontSize: 17,
                }}
              />
              <TextInput
                placeholder={"E-Mail"}
                value={email}
                onChangeText={(t) => setEmail(t)}
                inputType="email"
                style={{
                  marginTop: 20,
                  borderRadius: 6,
                  backgroundColor: "#ddd",
                  padding: 12,
                  fontSize: 17,
                }}
              />
              <TextInput
                inputType="password"
                value={password}
                onChangeText={(t) => setPassword(t)}
                placeholder={"Passwort"}
                style={{
                  marginTop: 20,
                  borderRadius: 6,
                  backgroundColor: "#ddd",
                  padding: 12,
                  fontSize: 17,
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "center",
                marginTop: 30,
              }}
            >
              <Switch
                value={saveData}
                thumbColor={"white"}
                activeThumbColor={"white"}
                trackColor={{ true: Colors.blue }}
                onValueChange={() => setSaveData((v) => !v)}
                style={{ marginRight: 12 }}
              />
              <Text style={{ fontSize: 15 }}>Administrator</Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignSelf: "center",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 40,
              }}
            >
              <ContinueButton
                disabled={
                  name === "" || email === "" || password === "" || isWaiting
                }
                action={async () => {
                  setIsWaiting(true);
                  let success = await registerUser();
                  if (success) {
                    navigation.goBack();
                  }
                  setIsWaiting(false);
                }}
              />
            </View>
            <View
              style={{
                alignSelf: "center",
                marginTop: 20,
                flexGrow: 1,
                justifyContent: "flex-end",
              }}
            ></View>
          </View>
        </KeyboardAwareScrollView>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  );
}

const LoginButton = ({ navigation }) => {
  return (
    <TouchableOpacity onPress={() => navigation.navigate("LoginScreen")}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,

          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 15, color: Colors.blue, fontWeight: "bold" }}>
          Ich habe schon einen Accout
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const ContinueButton = ({ action, disabled }) => {
  return (
    <TouchableOpacity onPress={action} disabled={disabled}>
      <View
        style={{
          opacity: disabled ? 0.5 : 1,
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            marginRight: 8,
            color: "white",
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          Jetzt registrieren
        </Text>
        <Icon name="arrow-forward" size={20} color={"white"} />
      </View>
    </TouchableOpacity>
  );
};
