import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  Image,
  Platform,
} from "react-native";
import { getValidDate, Seperator } from "./LandingScreen";
import Icon from "react-native-vector-icons/Ionicons";
import background from "../assets/background.png";
import { useEffect, useState } from "react";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { FIRESTORE_DB } from "../handlers/FirebaseHandler";
import { Colors } from "../const/Colors";

export async function getUserData(email) {
  try {
    let user = await getDoc(
      doc(FIRESTORE_DB, "user", email.toLowerCase().trim())
    );
    user = user.data();
    return user;
  } catch {
    return undefined;
  }
}

export default function EventOverviewScreen({ route, navigation }) {
  const { event, user } = route.params;

  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getDocs(
        collection(FIRESTORE_DB, "events", event.id, "answers")
      );
      let data = response.docs.map((r) => {
        return { data: r.data().data, id: r.id };
      });
      let ra = [];
      for (const d of data) {
        //console.log(d);
        let user = await getUserData(d.id);
        console.log("user", user);
        if (user !== undefined) {
          const coming = d.data[0].answer === "Ja";
          const partner = d.data[2].answer === "Ja";
          ra.push({ name: user.name, coming: coming, partner: partner });
        }
      }
      setData(ra);
    })();
  }, []);

  return (
    <View style={{ width: "100%", height: "100%", flex: 1 }}>
      <Image
        source={background}
        style={{
          position: "absolute",
          top: 0,
          opacity: 0.5,
          left: 0,
          resizeMode: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <SafeAreaView
        style={{
          flex: 1,
          height: "100%",
          ...Platform.select({ android: { marginTop: 20 } }),
        }}
      >
        <EventHeader event={event} />
        <Seperator style={{ opacity: 0.5 }} />
        <Text
          style={{
            padding: 12,
            marginTop: 12,
            fontSize: 17,
            fontWeight: "bold",
          }}
        >
          Bisherige Anmeldungen:
        </Text>
        <FlatList
          data={data}
          style={{ flex: 1, alignSelf: "stretch" }}
          renderItem={({ item }) => {
            return (
              <View style={{ padding: 12 }}>
                <View
                  style={{
                    alignSelf: "stretch",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: 17 }}>
                    {item.name} {item.partner ? "+ P " : ""}
                  </Text>
                  {item.coming ? (
                    <Icon name="checkmark-outline" size={36} color={"green"} />
                  ) : (
                    <Icon name="close-outline" size={36} color={"#8B0000"} />
                  )}
                </View>
                <Seperator
                  style={{ opacity: 0.25, marginBottom: -12, marginTop: 12 }}
                />
              </View>
            );
          }}
        />
        <View
          style={{
            alignSelf: "stretch",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 20,
            alignItems: "center",
          }}
        >
          <BackButton navigation={navigation} />
          <ContinueButton
            closed={event.closed}
            action={() =>
              navigation.navigate("QuestionScreen", {
                event: event,
                user: user,
              })
            }
          />
        </View>
      </SafeAreaView>
    </View>
  );
}

export const EventHeader = ({ event }) => {
  return (
    <View style={{ width: "100%", padding: 20 }}>
      <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: 3 }}>
        {getValidDate(event.date)}
      </Text>
      <Text>{event.description}</Text>
    </View>
  );
};

export const BackButton = ({ navigation }) => {
  return (
    <TouchableOpacity onPress={() => navigation.goBack()}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 12,
          paddingVertical: 8,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Icon name="arrow-back" size={20} color={Colors.blue} />
        <Text style={{ marginLeft: 8, fontSize: 15, color: Colors.blue }}>
          zurück
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const ContinueButton = ({ action, closed }) => {
  return (
    <TouchableOpacity onPress={action} disabled={closed}>
      <View
        style={{
          flexDirection: "row",
          opacity: closed ? 0.5 : 1,
          paddingHorizontal: 20,
          paddingVertical: 12,
          backgroundColor: "white",
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: Colors.blue,
        }}
      >
        <Text
          style={{
            marginRight: 8,
            color: "white",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Anmelden
        </Text>
        <Icon
          name={closed ? "lock-closed-outline" : "arrow-forward"}
          size={20}
          color={"white"}
        />
      </View>
    </TouchableOpacity>
  );
};
