import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBL7kAGo7tsz8Li_AffZMpkArDtGdAvMak",
  authDomain: "rotary-witten.firebaseapp.com",
  projectId: "rotary-witten",
  storageBucket: "rotary-witten.appspot.com",
  messagingSenderId: "336428447825",
  appId: "1:336428447825:web:148f97ebe8677d2e55a070",
  measurementId: "G-1VZVLYTB86",
};

const app = initializeApp(firebaseConfig);
export const FIRESTORE_DB = getFirestore(app);
