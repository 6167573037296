import uuid from "react-native-uuid";

import { useEffect, useState } from "react";
import {
  FlatList,
  Modal,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";

import { getValidDate, Seperator } from "../LandingScreen";
import {
  collection,
  doc,
  Firestore,
  onSnapshot,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { FIRESTORE_DB } from "../../handlers/FirebaseHandler";
import { Colors } from "../../const/Colors";

export default function AdminOverviewScreen({ navigation, route }) {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const unsub = onSnapshot(collection(FIRESTORE_DB, "events"), (snapshot) => {
      let data = snapshot.docs.map((s) => {
        return s.data();
      });
      data = data.filter((event) => {
        return (
          event.date.toDate() >= new Date().setDate(new Date().getDate() - 1)
        );
      });
      data = data.sort((d1, d2) =>
        d1.date.toDate() < d2.date.toDate() ? -1 : 0
      );
      setData(data);
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        width: "100%",
        height: "100%",
        ...Platform.select({ android: { paddingTop: 20 } }),
      }}
    >
      {open ? (
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: 0.7,
            backgroundColor: "black",
            zIndex: 100,
          }}
        ></View>
      ) : null}
      <Modal visible={open} transparent={true}>
        <View
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              borderRadius: 6,
              height: 200,
              width: 500,
              justifyContent: "center",
              padding: 30,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => setOpen(false)}
              style={{
                position: "absolute",
                top: 12,
                right: 12,
                width: 30,
                padding: 3,
                alignItems: "center",
                justifyContent: "center",
                height: 30,
              }}
            >
              <Icon
                name="close-outline"
                size={24}
                color={"black"}
                style={{ position: "absolute", top: 0, left: 0 }}
              />
            </TouchableOpacity>
            <Text
              style={{
                alignSelf: "flex-start",
                marginBottom: 12,
                fontWeight: "bold",
                fontSize: 17,
              }}
            >
              Datum:
            </Text>
            <input
              type="date"
              value={date.toISOString().split("T")[0]}
              onChange={(t) => {
                setDate(new Date(t.target.value));
              }}
              style={{
                alignSelf: "stretch",
                borderRadius: 6,
                borderWidth: 1,
                backgroundColor: "#ddd",
                padding: 8,
              }}
            ></input>
            <TouchableOpacity
              onPress={async () => {
                const id = uuid.v4();
                let body = {
                  date: date,
                  description: "",
                  id: id,
                  hasFood: true,
                  closed: false,
                };
                await setDoc(doc(FIRESTORE_DB, "events", id), body);
                body.date = Timestamp.fromDate(body.date);
                setOpen(false);
                navigation.navigate("AdminEventOverviewScreen", {
                  data: body,
                  shouldNotify: true,
                });
              }}
              style={{
                marginTop: 30,
                paddingVertical: 12,
                paddingHorizontal: 20,
                backgroundColor: Colors.blue,
                borderRadius: 6,
              }}
            >
              <Text
                style={{ color: "white", fontSize: 15, fontWeight: "bold" }}
              >
                Erstellen
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <View style={{ width: "100%", height: "100%" }}>
        <View
          style={{
            alignSelf: "stretch",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingRight: 20,
            alignItems: "center",
          }}
        >
          <Text style={{ padding: 20, fontSize: 22, fontWeight: "bold" }}>
            Übersicht
          </Text>
          <TouchableOpacity
            onPress={() => {
              setOpen(true);
            }}
          >
            <Icon name="add" size={32} />
          </TouchableOpacity>
        </View>
        <Seperator />
        <FlatList
          data={data}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("AdminEventOverviewScreen", {
                    data: item,
                    shouldNotify: false,
                  })
                }
              >
                <View style={{ alignSelf: "stretch", padding: 12 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "stretch",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text style={{ fontSize: 15, marginVertical: 8 }}>
                      {getValidDate(item.date)}
                    </Text>
                    <Icon name="arrow-forward" size={24} />
                  </View>
                  <Seperator
                    style={{ marginTop: 12, marginBottom: -12, opacity: 0.25 }}
                  />
                </View>
              </TouchableOpacity>
            );
          }}
        />
        <Seperator />
        <View
          style={{
            margin: 20,
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <BackButton navigation={navigation} />
          <CloseEventButton
            action={() => navigation.navigate("RegistryScreen")}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
export const BackButton = ({ navigation }) => {
  return (
    <TouchableOpacity onPress={() => navigation.goBack()}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 12,
          paddingVertical: 8,
          backgroundColor: Colors.blue,
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
        }}
      >
        <Icon name="arrow-back" size={20} color={"white"} />
        <Text style={{ marginLeft: 8, fontSize: 15, color: "white" }}>
          zurück
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const CloseEventButton = ({ action }) => {
  return (
    <TouchableOpacity onPress={action}>
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 20,
          paddingVertical: 12,
          borderRadius: 6,
          alignSelf: "flex-start",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <Text
          style={{
            color: Colors.blue,
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          Neuen Nutzer erstellen
        </Text>
      </View>
    </TouchableOpacity>
  );
};
